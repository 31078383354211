
@import "bourbon";
/* ---------- FONTS ---------- */
//пример подключения шрифта (для быстрого подкл. используйте snippet iff)
@include font-face("OpenSansRegular", "../fonts/OpenSansRegular/OpenSansRegular", $file-formats: eot woff ttf);
@include font-face("GothamProRegular", "../fonts/GothamProRegular/GothamProRegular", $file-formats: eot woff ttf);
@include font-face("GothamProBold", "../fonts/GothamProBold/GothamProBold", $file-formats: eot woff ttf);
@include font-face("RobotoRegular", "../fonts/RobotoRegular/RobotoRegular", $file-formats: eot woff ttf);
@include font-face("RobotoBold", "../fonts/RobotoBold/RobotoBold", $file-formats: eot woff ttf);
@include font-face("ProximaNovaRegular", "../fonts/ProximaNovaRegular/ProximaNovaRegular", $file-formats: eot woff ttf);
@include font-face("VisbyCF-Bold", "../fonts/VisbyCF-Bold/VisbyCF-Bold", $file-formats: eot woff ttf);
@include font-face("VisbyCF-ExtraBold", "../fonts/VisbyCF-ExtraBold/VisbyCF-ExtraBold", $file-formats: eot woff ttf);
@include font-face("VisbyCF-Light", "../fonts/VisbyCF-Light/VisbyCF-Light", $file-formats: eot woff ttf);
@include font-face("VisbyCF-DemiBold", "../fonts/VisbyCF-DemiBold/VisbyCF-DemiBold", $file-formats: eot woff ttf);
@include font-face("MyriadProRegular", "../fonts/MyriadProRegular/MyriadProRegular", $file-formats: eot woff ttf);
/* ---------- @IMPORT VARIABLES ---------- */
@import "_variables.scss";


/* ---------- @IMPORT BUTTONS ---------- */
@import "_buttons.scss";


/* ----------MAIN STYLE---------- */
body {
	font-family: "OpenSansRegualr", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
	min-width: 320px;
	color: #333333;
	position: relative;
	background-color: #ffffff;
}

////Удалить или закоментировать при адаптивном дизайне!//////////////
.container {width: 960px ;}
//////////////////////////////////////////////////////////////////////

.hidden {
	display: none;
}

.wrapper {
	margin: 0 auto;
}

p {
	margin: 0;
	padding: 0;
}

br.sm {display: none;}
br.xs {display: none;}

//validatorGM styles//
 form input.error {
 	box-shadow: inset 0px 0px 20px 1px rgba(255,0,0,0.3);
 	border: 2px solid red !important;}
 form input.not_error {
 	box-shadow: inset 0px 0px 20px 1px rgba(100,255,100,0.3);
 	border: 2px solid #99FF99 !important;}

/* ----------IMPORT HEADER STYLES---------- */
@import "_header.scss";

/* ----------TYPOGRAPHY---------- */



/* ----------SECTIONS---------- */




/* ----------IMPORT FOOTER STYLES---------- */
@import "_footerGM.scss";

/* ----------IMPORT MEDIA STYLES---------- */
@import "_media.scss";