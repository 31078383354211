.success-header {
	text-align: center;
	.topline {
		background-color: #2d2b2b;
		height: 70px;
		text-align: center;
		padding-top: 20px;
	}
	.date {
		display: inline-block;
		padding-left: 40px;
		background: url(../img/date-icon.png) 0 50% no-repeat;
		color: #ffffff;
		font-family: VisbyCF-Bold;
		font-size: 22px;
		letter-spacing: 4px;
		span {
			font-family: VisbyCF-Light;
		}
	}
	.content {
		text-align: left;
		min-height: 400px;
		background: url(../img/live-bg.jpg) 50% -80px no-repeat;
		padding-top: 70px;
	}
	.title {
		color: #ffffff;
		font-family: "MyriadProRegular";
		font-size: 28px;
		text-align: center;
		line-height: 38px;
		text-transform: uppercase;
		margin-bottom: 80px;
	}

	.video-block {
		width: 100%;
		max-width: 540px;
		display: inline-block;
		vertical-align: top;
		// height: 280px;
		iframe {
			height: 280px;
		}
	}
	.comments-block {
		display: inline-block;
		vertical-align: top;
		background-color: #fff;
		width: 100%;
		max-width: 390px;
	}
}

#soc {
	margin-bottom: 20px;
	.like-title {
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 17px;
		font-family: VisbyCF-DemiBold;
		text-align: center; }
	 .fb-like {
		 left: 50%;
		 -ms-transform: translateX(-50%);
		 transform: translateX(-50%); }
}
