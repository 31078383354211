/*********************************************\
		Desktop First Method
\*********************************************/
/* Medium Devices, Notebooks */
@media only screen and (max-width : 1130px) {	

}


/* Small Devices, Tablets */
@media only screen and (max-width : 960px) {	
 .container {width: 720px;}
 br.sm {display: block;}
 br.xs {display: none;}

 .success-header {
 	text-align: center;
 	.content {
 		text-align: center;
 	}
 	.video-block {
		margin-bottom: 30px;
 	}
 	#soc .fb-like {
		display: inline-block;
		position: absolute;
 	}
 }
 .more {
 	margin-top: 50px;
 }

}


/* Extra Small Devices, Phones */
@media only screen and (max-width : 720px) {
	.container {
		width: 340px;
		overflow: hidden;
	}
	br.sm {display: none;}
	br.xs {display: block;}

	/*Disable Animation on Mobile Devices*/
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}

	.success-header {
		.date {
			font-size: 15px;
		}
		.title {
			font-size: 24px;
			line-height: 30px;
			margin-bottom: 30px;
		}
		.content {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		.more {
			width: 100%;
			font-size: 14px;
		}
	}

}


/* Custom, iPhone Retina */
@media only screen and (max-width : 340px) {
		.container {
		padding: 0;
		overflow: hidden;
		width: 320px;}
}


/*********************************************\
		Mobile First Method
\*********************************************/


/* Extra Small Devices, Phones */
@media only screen and (min-width : 320px) {

}


/* Small Devices, Tablets */
@media only screen and (min-width : 720px) {

}


/* Medium Devices, Desktops */
@media only screen and (min-width : 960px) {

}


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1130px) {

}