.more {
	width: 375px;
	height: 59px;
	border-radius: 28px;
	background-color: #e9fb24;
	text-align: center;
	line-height: 65px;
	color: #1a1a1a;
	font-family: VisbyCF-Bold;
	font-size: 18px;
	line-height: 59px;
	padding-right: 10px;
	display: block;
	margin: 0 auto;
	background: #e9fb24 url(../img/arr.png) 96% 50% no-repeat;
	transition: all 0.3s linear;
	border: none;
	cursor: pointer;
	&:hover,
	&:active,
	&:focus {
		transform: scale(1.02);
		text-decoration: none;
		color: #1a1a1a;
	}
}

.download-btn{
	width: 95px;
	height: 30px;
	border-radius: 15px;
	border: 1px solid rgba(255, 255, 255, 0.24);
	transition: all 0.3s linear;
	text-align: center;
	color: #ffffff;
	font-family: VisbyCF-Bold;
	font-size: 14px;
	display: inline-block;
	line-height: 30px;
	&:hover,
	&:active,
	&:focus {
		transform: scale(1.02);
		text-decoration: none;
		color: #fff;
	}
}
